/* eslint-disable prettier/prettier */
import { combineReducers } from 'redux'
import { roleReducer } from './allRedudcers/roleReducer'
import { userReducer } from './allRedudcers/userReducer'
import { categoriesReducer } from './allRedudcers/categoriesReducer'
import { productsReducer } from './allRedudcers/productsReducer'
import { faqReducer } from './allRedudcers/faqReducer'
import { basicDetailReducer } from './allRedudcers/basicDetailReducer'
import { testimonialReducer } from './allRedudcers/testimonialReducer'
import { stateReducer } from './allRedudcers/stateReducer'
import { citiesReducer } from './allRedudcers/citiesReducer'
import { languageReducer } from './allRedudcers/languageReducer'
import { pujaReducer } from './allRedudcers/pujaReducer'
import { panditReducer } from './allRedudcers/panditReducer'
import { panditEducationReducer } from './allRedudcers/panditEducationReducer'
import { allOrdersReducer } from './allRedudcers/allOrdersReducer'
import { allBookingReducer } from './allRedudcers/allBookingReducer'
import { allPackageReducer } from './allRedudcers/allPackageReducer'
import { blogsReducer } from './allRedudcers/blogsReducer'
import { contactRequestReducer } from './allRedudcers/contactRequestReducer'
import { aboutusReducer } from './allRedudcers/aboutusReducer'
import { chatandcallContactRequestReducer } from './allRedudcers/chatandcallContactRequestReducer'
import { packageAddOnsReducer } from './allRedudcers/packageAddOnsReducer'
import { galleriesReducer } from './allRedudcers/galleriesReducer'
import { promoCodesReducer } from './allRedudcers/promoCodesReducer'

export default combineReducers({
  roleReducer,
  userReducer,
  categoriesReducer,
  productsReducer,
  faqReducer,
  basicDetailReducer,
  testimonialReducer,
  stateReducer,
  citiesReducer,
  languageReducer,
  pujaReducer,
  panditReducer,
  panditEducationReducer,
  allOrdersReducer,
  allBookingReducer,
  allPackageReducer,
  blogsReducer,
  contactRequestReducer,
  aboutusReducer,
  chatandcallContactRequestReducer,
  packageAddOnsReducer,
  galleriesReducer,
  promoCodesReducer,
})
