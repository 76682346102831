import { SET_PROMO_CODE } from '../constants'

const promoCodesData = []
export const promoCodesReducer = (state = promoCodesData, action) => {
  switch (action.type) {
    case SET_PROMO_CODE:
      return action.data.data
    default:
      return state
  }
}
