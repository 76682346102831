// ------- roles --------
export const ROLES = 'roles'
export const SET_ROLES = 'set_roles'
// ------- roles --------

// ------- users --------
export const USERS = 'users'
export const SET_USERS = 'set_users'
// ------- users --------

// ------- categories --------
export const CATEGORIES = 'categories'
export const SET_CATEGORIES = 'set_categories'
// ------- categories --------

// ------- products --------
export const PRODUCTS = 'products'
export const SET_PRODUCTS = 'set_products'
// ------- products --------

// ------- faqs --------
export const FAQS = 'faqs'
export const SET_FAQS = 'set_faqs'
// ------- faqs --------

// ------- basic_setup --------
export const BASIC_SETUP = 'basic_setup'
export const SET_BASIC_SETUP = 'set_basic_setup'
// ------- basic_setup --------

// ------- testimonials --------
export const TESTIMONIALS = 'testimonials'
export const SET_TESTIMONIALS = 'set_testimonials'
// ------- testimonials --------

// ------- states --------
export const STATES = 'states'
export const SET_STATES = 'set_states'
// ------- states --------

// ------- cities --------
export const CITIES = 'cities'
export const SET_CITIES = 'set_cities'
// ------- cities --------

// ------- languages --------
export const LANGUAGES = 'languages'
export const SET_LANGUAGES = 'set_languages'
// ------- languages --------

// ------- pujas --------
export const PUJAS = 'pujas'
export const SET_PUJAS = 'set_pujas'
// ------- pujas --------

// ------- pandits --------
export const PANDITS = 'pandits'
export const SET_PANDITS = 'set_pandits'
// ------- pandits --------

// ------- pandit_educations --------
export const PANDIT_EDUCATIONS = 'pandit_educations'
export const SET_PANDIT_EDUCATIONS = 'set_pandit_educations'
// ------- pandit_educations --------

// ------- all_orders --------
export const ALLORDERS = 'all_orders'
export const SET_ALLORDERS = 'set_all_orders'
// ------- all_orders --------

// ------- all_bookings --------
export const ALLBOOKINGS = 'all_bookings'
export const SET_ALLBOOKINGS = 'set_all_bookings'
// ------- all_bookings --------

// ------- all_bookings --------
export const PACKAGES = 'packages'
export const SET_PACKAGES = 'set_packages'
// ------- all_bookings --------

// ------- all_bookings --------
export const BLOGS = 'blogs'
export const SET_BLOGS = 'set_blogs'
// ------- all_bookings --------

// ------- all_bookings --------
export const CONTACT_REQUESTS = 'contact_requests'
export const SET_CONTACT_REQUESTS = 'set_contact_requests'
// ------- all_bookings --------

// ------- all_bookings --------
export const CHATANDCALL_REQUESTS = 'chatandcall_requests'
export const SET_CHATANDCALL_REQUESTS = 'set_chatandcall_requests'
// ------- all_bookings --------

// ------- all_bookings --------
export const ABOUTUS = 'aboutus'
export const SET_ABOUTUS = 'set_aboutus'
// ------- all_bookings --------

// ------- all_bookings --------
export const PACKAGEADDONS = 'packageaddons'
export const SET_PACKAGEADDONS = 'set_packageaddons'
// ------- all_bookings --------

// ------- all_bookings --------
export const GALLERIES = 'galleries'
export const SET_GALLERIES = 'set_galleries'
// ------- all_bookings --------

// ------- all_bookings --------
export const PROMO_CODE = 'promocode'
export const SET_PROMO_CODE = 'set_promocode'
// ------- all_bookings --------
